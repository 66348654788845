import { ClientFormUserRole, ClientFormUserRoleValues } from '../models/ClientFormUserRoles';
import { ClientFormUser } from '../models/ClientFormUser';
import { Roles } from '../models/Role';
import User from '../models/User';
import { hasPermission } from '../hooks/permissions/usePermissions';
import ClientFormVersionHistory, { PerformedAction } from '../models/ClientFormVersionHistory';

const hasFormRole = (roles: ClientFormUserRoleValues | ClientFormUserRoleValues[], currentFormUser: ClientFormUser | null): boolean => {
  if (!Array.isArray(roles)) {
    roles = [roles];
  }
  const currentFormUserRoles = currentFormUser?.role ? [currentFormUser.role, ...(currentFormUser.otherRoles?.map((x) => x.role) || [])] : [];
  return roles.find((role) => currentFormUserRoles.includes(role)) != null;
};

export const canCurrentUserSubmitForm = (currentFormUser: ClientFormUser | null, clientId: string, currentUser: User | null): boolean => {
  if (hasPermission(clientId, currentUser, [Roles.TeamMember])) {
    return true;
  }
  return hasFormRole([ClientFormUserRole.Owner, ClientFormUserRole.Contributor], currentFormUser);
};

export const canCurrentUserValidateForm = (currentFormUser: ClientFormUser | null, clientId: string, currentUser: User | null): boolean => {
  if (hasPermission(clientId, currentUser, [Roles.TeamMember])) {
    return true;
  }
  // Determine if a owner can review the form
  const roles: ClientFormUserRoleValues[] = [ClientFormUserRole.Validator];
  if (
    (currentFormUser?.requiresAction && currentFormUser.role === ClientFormUserRole.Owner) ||
    currentFormUser?.otherRoles?.some((x) => x.requiresAction && x.role === ClientFormUserRole.Owner)
  ) {
    roles.push(ClientFormUserRole.Owner);
  }
  return hasFormRole(roles, currentFormUser);
};

export const canCurrentUserApproveForm = (currentFormUser: ClientFormUser | null, clientId: string, currentUser: User | null): boolean => {
  if (hasPermission(clientId, currentUser, [Roles.TeamMember])) {
    return true;
  }
  // Determine if a owner can approve the form
  const roles: ClientFormUserRoleValues[] = [ClientFormUserRole.Approver];
  if (
    (currentFormUser?.requiresAction && currentFormUser.role === ClientFormUserRole.Owner) ||
    currentFormUser?.otherRoles?.some((x) => x.requiresAction && x.role === ClientFormUserRole.Owner)
  ) {
    roles.push(ClientFormUserRole.Owner);
  }
  return hasFormRole(roles, currentFormUser);
};

export const canCurrentUserReopenForm = (currentFormUser: ClientFormUser | null, clientId: string, currentUser: User | null): boolean => {
  if (hasPermission(clientId, currentUser, [Roles.TeamMember])) {
    return true;
  }
  return hasFormRole([ClientFormUserRole.Owner, ClientFormUserRole.Contributor], currentFormUser);
};

export const canCurrentUserSubmitSection = (currentFormSectionUser: ClientFormUser | null, clientId: string, currentUser: User | null): boolean => {
  if (hasPermission(clientId, currentUser, [Roles.TeamMember])) {
    return true;
  }
  return hasFormRole([ClientFormUserRole.Owner, ClientFormUserRole.Contributor], currentFormSectionUser);
};

export const canCurrentUserApproveSection = (currentFormSectionUser: ClientFormUser | null, clientId: string, currentUser: User | null): boolean => {
  if (hasPermission(clientId, currentUser, [Roles.TeamMember])) {
    return true;
  }
  return hasFormRole([ClientFormUserRole.Owner, ClientFormUserRole.Approver], currentFormSectionUser);
};

export interface UserActionStatus {
  hasPerformedAction: boolean;
  canPerformAction: boolean;
  reasonKey?: string;
  pendingActionUsers?: ClientFormUser[];
  self: ClientFormUser | undefined;
}

export const determineReviewApprovalStatus = (
  clientId: string,
  currentUser: User,
  formUsers: ClientFormUser[],
  userHistories: ClientFormVersionHistory[],
  requiredRole: ClientFormUserRoleValues,
  isLastAction: boolean,
): UserActionStatus => {
  const actionToPerform = requiredRole === ClientFormUserRole.Approver ? PerformedAction.ClientFormApprove : PerformedAction.ClientFormValidate;
  const allActionedUsers = formUsers.map((user) => ({
    ...user,
    actionPerformed: userHistories.find((history) => history.userId === user.id && history.performedAction === actionToPerform)?.performedAction,
  }));
  const actionedUsers = allActionedUsers.filter((user) => user.role === requiredRole);
  const sortedUsers = actionedUsers.sort((a, b) => {
    const aSortOrder = a.sortOrder;
    const bSortOrder = b.sortOrder;

    // Sort by sortOrder if both are defined
    if (aSortOrder !== null && aSortOrder !== undefined && bSortOrder !== null && bSortOrder !== undefined) {
      return aSortOrder - bSortOrder;
    }
    // If only a has sortOrder, it comes first
    if (aSortOrder !== null && aSortOrder !== undefined) return -1;
    // If only b has sortOrder, it comes first
    if (bSortOrder !== null && bSortOrder !== undefined) return 1;
    // Keep original order if neither has sortOrder
    return 0;
  });

  const self = sortedUsers.find((user) => user.id === currentUser.id);
  const isOptional = !self?.requiresAction;
  const isSequential = sortedUsers.some((user) => user.sortOrder !== null && user.sortOrder !== undefined);
  const lastSequentialActionedUser = sortedUsers
    .slice()
    .reverse()
    .find((user) => user.actionPerformed === actionToPerform && user.sortOrder !== null && user.sortOrder !== undefined);

  // Determine if the current user has performed the action, could also be a higher system role that has performed the action
  const currentFormUser = allActionedUsers.find((user) => user.id === currentUser.id);
  const hasPerformedAction = Boolean(currentFormUser && currentFormUser.actionPerformed === actionToPerform);

  // Determine if the current user can perform action
  let canCurrentUserPerformAction = Boolean(self && !isSequential && !hasPerformedAction);
  let reason: string | undefined;

  // Determine if the current user can perform action in a sequential workflow
  if (isSequential && !hasPerformedAction) {
    if (!lastSequentialActionedUser && self?.sortOrder === 0) {
      canCurrentUserPerformAction = true;
    } else if (lastSequentialActionedUser && self?.sortOrder === lastSequentialActionedUser.sortOrder! + 1) {
      canCurrentUserPerformAction = true;
    } else if (!isOptional) {
      canCurrentUserPerformAction = false;
      reason = 'form:action-required-from-others';
    }
  }

  // Get the list of actioners before the current user's turn
  let pendingActionUsers: ClientFormUser[] = [];
  if (self && isSequential && !canCurrentUserPerformAction && !hasPerformedAction) {
    pendingActionUsers = sortedUsers.filter(
      (user) =>
        user.actionPerformed !== actionToPerform &&
        user.sortOrder !== null &&
        user.sortOrder !== undefined &&
        self &&
        user.sortOrder < self.sortOrder!,
    );
  }
  // Also show this to the user if they have already performed the action or if they are not in the list
  else if (hasPerformedAction || (!self && currentFormUser)) {
    // Get the list of required users who still have to action
    pendingActionUsers = sortedUsers.filter((user) => user.requiresAction && user.actionPerformed !== actionToPerform);
  }

  const requiredUsers = actionedUsers.filter((x) => x.requiresAction);
  const isActionCompleted =
    requiredUsers.length > 0
      ? requiredUsers.every((user) => user.actionPerformed === actionToPerform)
      : actionedUsers.some((user) => user.actionPerformed === actionToPerform);

  let response: UserActionStatus = {
    canPerformAction: canCurrentUserPerformAction,
    hasPerformedAction,
    reasonKey: reason,
    pendingActionUsers,
    self: self,
  };

  // Determine if the owner needs to acknowledge the action
  if (isLastAction) {
    const ownerAction = determineOwnerAcknowledgment(isActionCompleted, formUsers, userHistories, actionToPerform, currentUser, pendingActionUsers);
    if (ownerAction) {
      response = ownerAction;
    }
  }

  if (!response.canPerformAction && !response.hasPerformedAction && !response.reasonKey) {
    // Determine if the user can perform the action based on system role
    response.canPerformAction = hasPermission(clientId, currentUser, [Roles.TeamMember]);
    if (response.canPerformAction) {
      response.hasPerformedAction = userHistories.some((history) => history.userId === currentUser.id && history.performedAction === actionToPerform);
    } else {
      response.reasonKey = 'form:action-insufficient-permissions';
    }
  }

  return response;
};

const determineOwnerAcknowledgment = (
  isActionCompleted: boolean,
  formUsers: ClientFormUser[],
  userHistories: ClientFormVersionHistory[],
  actionToPerform: PerformedAction,
  currentUser: User,
  pendingActionUsers: ClientFormUser[],
): UserActionStatus | null => {
  const shouldOwnerAcknowledge = formUsers.some((user) => user.role === ClientFormUserRole.Owner && user.requiresAction);

  if (!isActionCompleted || !shouldOwnerAcknowledge) {
    return null;
  }

  const owner = formUsers.find((user) => user.role === ClientFormUserRole.Owner);
  const ownerPerformedAction = userHistories.find(
    (history) => history.userId === owner?.id && history.role === ClientFormUserRole.Owner && history.performedAction === actionToPerform,
  )?.performedAction;

  if (!owner || ownerPerformedAction === actionToPerform) {
    return null;
  }

  const ownerWithAction = { ...owner, actionPerformed: ownerPerformedAction };
  const isCurrentUserOwner = owner.id === currentUser.id;

  return {
    canPerformAction: isCurrentUserOwner,
    hasPerformedAction: false,
    reasonKey: isCurrentUserOwner ? undefined : 'form:action-required-from-others',
    self: isCurrentUserOwner ? ownerWithAction : undefined,
    pendingActionUsers: [...pendingActionUsers, ownerWithAction],
  };
};

export default hasFormRole;
